angular.module('MyHippoCommons.Directives').directive('saveEndorsementModal', function () {
    return {
        scope: {},
        restrict: 'E',
        templateUrl: '/modals/save-endorsement/save-endorsement.html',
        controller: function ($scope, $mdDialog, EndorsementService, $state, $stateParams, PolicyService) {
            $scope.saveDraft = function () {
                $mdDialog.show({
                    parent: angular.element(document.body),
                    templateUrl:  '/modals/save-endorsement/draft-saved.html',
                    controller: function($scope) {
                        $scope.emailDraft = function() {
                            EndorsementService.updateEndorsement();
                            $mdDialog.hide();
                        };
                        $scope.cancel = function() {
                            $mdDialog.cancel();
                        };
                    }
                });
            };

            $scope.PolicyService = PolicyService;

            $scope.applyChanges = function () {
                EndorsementService.bindEndorsement().then(() => {
                    $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { mode: 'view' }));
                });
            };
        },
    };
});